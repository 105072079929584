/* Opening & closing buttons */

.m-panel.m-plugin-viewmanagement.collapsed {
    height: 40px;
}

.m-plugin-viewmanagement.opened .m-panel-btn.g-cartografia-flecha-derecha,
.m-plugin-viewmanagement.opened .m-panel-btn.g-cartografia-flecha-izquierda {
    background-color: white !important;
    color: #71a7d3 !important;
}

.m-areas>div.m-area.m-right>div.m-plugin-viewmanagement.opened>button.m-panel-btn {
    position: absolute;
    left: -40px;
}

.m-areas>div.m-area.m-left>div.m-plugin-viewmanagement.opened>button.m-panel-btn {
    position: absolute;
    right: -40px;
}

/* Title and images container */

.m-control.m-container.m-viewmanagement {
    width: 250px;
}

/* Título */

div#m-viewmanagement-title {
    background-color: #71a7d3;
    color: white;
    display: block;
    font-size: 15px;
    height: 40px;
    line-height: 40px;
    padding: 0 5px;
    text-align: center;
    width: 100%;
}

div#m-viewmanagement-previews {
    background-color: white;
}

#m-viewmanagement-viewhistory,
#m-viewmanagement-zoomextent,
#m-viewmanagement-zoompanel,
#m-viewmanagement-predefinedzoom {
    padding: 10px;
    font-size: 22px;
    cursor: pointer;
}

.m-predefinedzoom-button-container {
    display: flex;
    border: 1px solid #adadad;
    margin: 2px;
    background-color: #F7F6F6;
    padding: 5px;
}

.m-predefinedzoom-namezoom {
    display: inline;
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.viewmanagement-icon-flecha-historial.activated,
.viewmanagement-icon-zoompanel.activated,
.viewmanagement-icon-expand.activated:before ,
.viewmanagement-icon-zoom-extension.activated,
.viewmanagement-icon-home.activated,
.viewmanagement-icon-flecha-izquierda3.activated:before,
.viewmanagement-icon-flecha-derecha3.activated:before,
.viewmanagement-icon-zoom-in.activated:before,
.viewmanagement-icon-zoom-out.activated:before {
    color: #71a7d3 !important;
}

#m-viewmanagement-previews {
    display: flex;
    justify-content: center;
}

#m-viewhistory-panel,
#m-zoompanel-panel {
    background-color: #ededed;
    border-top: 1px solid #adadad;
    padding: 10px;
    text-align: center;
    width: 230px;
}

#m-predefinedzoom-panel {
    background-color: #ededed;
    border-top: 1px solid #adadad;
    padding: 5px;
    max-height: 220px;
    overflow: auto;
    width: 240px;
}

#m-historyprevious-button,
#m-historynext-button,
#m-zoompanel-zoomin,
#m-zoompanel-zoomout,
#m-predefinedzoom-panel .viewmanagement-icon-expand {
    border: none;
    background-color: transparent;
    font-size: 22px;
    cursor: pointer;
}

/* Images container */

.m-viewmanagement .activeviewmanagementDiv {
    border: 5px solid #92c1e7 !important;
}

/* Bottom positions fix when images row turns into columns */

.m-bottom .m-control.m-container.m-viewmanagement>#m-viewmanagement-previews {
    background-color: white;
    display: flex;
    /* width: 220px; */
}

.m-bottom.m-left>.m-panel.m-plugin-viewmanagement.opened>.m-panel-controls {
    display: flow-root;
}

.m-bottom.m-left>.m-plugin-viewmanagement.opened>.m-panel-btn {
    position: relative;
    right: -40px;
}

.m-areas>div.m-area>div.m-panel.opened.m-plugin-viewmanagement {
    background-color: transparent !important;
    /* box-shadow: none !important; */
    order: 0;
}

.m-areas>div.m-area.m-left>div.m-panel.opened.m-plugin-viewmanagement {
    display: block !important;
}

.m-areas>div.m-area.m-bottom.m-right>div.m-panel.opened.no-collapsible.m-plugin-viewmanagement>div.m-panel-controls>div.m-control.m-container.m-viewmanagement {
    padding-right: 0px;
}

.m-areas>div.m-area>div.m-panel.collapsed>div.m-panel-controls {
    display: none;
}

.m-areas>div.m-area>div.m-panel.m-plugin-viewmanagement.collapsed>div.m-panel-controls>div {
    display: none;
}

/* Firefox fix */

@-moz-document url-prefix() {
    .m-bottom.m-right .m-plugin-viewmanagement.opened {
        display: flex;
    }

    .m-areas>div.m-area.m-bottom.m-right>div.m-panel.opened.m-plugin-viewmanagement>div.m-panel-controls>div.m-control.m-container.m-viewmanagement {
        padding-right: unset;
    }

    .m-areas>div.m-area.m-bottom.m-right>div.m-panel.opened.no-collapsible.m-plugin-viewmanagement>div.m-panel-controls>div.m-control.m-container.m-viewmanagement {
        padding-right: 0px;
    }
}

/* Mobile fix */

@media only screen and (max-width: 1000px) {
    .m-areas>div.m-area.m-right>div.m-panel.m-plugin-viewmanagement.opened {
        margin-right: 10px;
    }

    .m-areas>div.m-area.m-right>div.m-panel.m-plugin-viewmanagement.opened {
        margin-left: 10px;
    }

    .m-areas>div.m-area>div.m-panel.opened {
        z-index: 10;
    }

    .m-areas>div.m-area>div.m-panel.m-plugin-viewmanagement.opened>div.m-panel-controls>div.m-control.m-container.m-viewmanagement>div#m-viewmanagement-title {
        font-size: 13px;
        padding-left: 0;
        padding-right: 0;
    }

    .m-areas>div.m-area>div.m-panel.m-plugin-viewmanagement.opened>div.m-panel-controls>div.m-control.m-container.m-viewmanagement>div#m-viewmanagement-previews>div>span {
        font-size: 1.3em;
        line-height: 40px;
    }

    /* Firefox fix */

    @-moz-document url-prefix() {
        .m-bottom.m-left>.m-panel.m-plugin-viewmanagement.opened>.m-panel-controls {
            display: flow-root;
        }

        .m-areas>div.m-area>div.m-panel.m-plugin-viewmanagement.opened {
            width: auto;
        }

        .m-bottom.m-left>.m-panel.m-plugin-viewmanagement.opened>.m-panel-controls {
            display: flex;
        }

        .m-bottom.m-left>.m-panel.m-plugin-viewmanagement.opened>button {
            left: 0px;
        }

        .m-areas>div.m-area>div.m-panel>div.m-panel-controls {
            display: flex;
        }
    }

    /* Edge fix */

    @supports (-ms-ime-align:auto) {

        .m-areas>div.m-area>div.m-panel>div.m-panel-controls {
            padding-left: 0px;
        }

        .m-bottom.m-left>.m-plugin-viewmanagement.opened>.m-panel-btn {
            position: static;
        }
    }
}

/* Edge fix */
@supports (-ms-ime-align:auto) {
    .m-areas>div.m-area>div.m-panel>div.m-panel-controls {
        padding-left: 40px;
    }

    .m-bottom.m-left>.m-plugin-viewmanagement.opened>.m-panel-btn {
        right: 0px;
    }
}

@font-face {
    font-family: 'viewmanagement';
    src: url('../fonts/viewmanagement.eot?v63csk');
    src: url('../fonts/viewmanagement.eot?v63csk#iefix') format('embedded-opentype'),
        url('../fonts/viewmanagement.ttf?v63csk') format('truetype'),
        url('../fonts/viewmanagement.woff?v63csk') format('woff'),
        url('../fonts/viewmanagement.svg?v63csk#viewmanagement') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="viewmanagement-icon-"],
[class*=" viewmanagement-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'viewmanagement' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.viewmanagement-icon-zoom-in:before {
    content: "\e802";
    color: #646464;
}

.viewmanagement-icon-zoom-out:before {
    content: "\e803";
    color: #646464;
}

.viewmanagement-icon-zoompanel:before {
    content: "\e805";
}

.viewmanagement-icon-home:before {
    content: "\e900";
}

.viewmanagement-icon-expand:before {
    content: "\e921";
    color: #646464;
}

.viewmanagement-icon-flecha-derecha3:before {
    content: "\e1900";
    color: #646464;
}

.viewmanagement-icon-flecha-izquierda3:before {
    content: "\e1901";
    color: #646464;
}

.viewmanagement-icon-zoom-extension:before {
    content: "\e1903";
}

.viewmanagement-icon-zoom-mapa:before {
    content: "\e83b";
}

.viewmanagement-icon-flecha-historial:before {
    content: "\e89f";
}


div.m-area.m-top.m-left>div.m-plugin-viewmanagement.m-panel>button.m-panel-btn {
    font-family: viewmanagement !important;
}

div.m-area.m-top.m-left>div.m-plugin-viewmanagement.m-panel.opened>button.m-panel-btn {
    font-family: g-cartografia !important;
}

div.m-area.m-top.m-right>div.m-plugin-viewmanagement.m-panel>button.m-panel-btn {
    font-family: viewmanagement !important;
}

div.m-area.m-top.m-right>div.m-plugin-viewmanagement.m-panel.opened>button.m-panel-btn {
    font-family: g-cartografia !important;
}

div.m-area.m-bottom.m-right>div.m-plugin-viewmanagement.m-panel>button.m-panel-btn {
    font-family: viewmanagement !important;
}

div.m-area.m-bottom.m-right>div.m-plugin-viewmanagement.m-panel.opened>button.m-panel-btn {
    font-family: g-cartografia !important;
}

div.m-area.m-bottom.m-left>div.m-plugin-viewmanagement.m-panel>button.m-panel-btn {
    font-family: viewmanagement !important;
}

div.m-area.m-bottom.m-left>div.m-plugin-viewmanagement.m-panel.opened>button.m-panel-btn {
    font-family: g-cartografia !important;
}